<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <brand-logo />
      <!-- /Brand logo-->

      <!-- Left Text-->
      <left-image :src="imgUrl" />
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto max-width"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('login.textWelcome') }} {{ appName }}! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('login.textGuide') }}
          </b-card-text>

          <!-- login with google -->
          <!--          <b-button-->
          <!--            variant="outline-primary"-->
          <!--            block-->
          <!--          >-->
          <!--            <BIconGoogle class="mr-1" />-->
          <!--            <span class="align-middle">-->
          <!--              {{ $t('common.btnLoginGoogle') }}-->
          <!--            </span>-->
          <!--          </b-button>-->

          <!--          &lt;!&ndash; divider &ndash;&gt;-->
          <!--          <div class="divider my-2">-->
          <!--            <div class="divider-text">-->
          <!--              {{ $t('common.textOr') }}-->
          <!--            </div>-->
          <!--          </div>-->

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label-for="login-email"
                :label="$t('common.labelEmail')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-input-group-prepend
                      is-text
                    >
                      <feather-icon icon="UserIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="login-email"
                      v-model.trim="username"
                      tabindex="1"
                      name="login-email"
                      :placeholder="$t('common.placeholderEmail')"
                      :state="errors.length > 0 ? false:null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">
                    {{ $t('common.labelPassword') }}
                  </label>
                  <!--                  <b-link :to="{name:'forgot-password'}">-->
                  <!--                    <small>-->
                  <!--                      {{ $t('login.linkForgotPassword') }}-->
                  <!--                    </small>-->
                  <!--                  </b-link>-->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-input-group-prepend
                      is-text
                    >
                      <feather-icon icon="LockIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="login-password"
                      v-model.trim="password"
                      tabindex="2"
                      class="form-control-merge"
                      name="login-password"
                      placeholder="············"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <btn-loading
                tabindex="4"
                type="submit"
                variant="primary"
                block
                :loading="loading"
                @click="validationForm"
              >
                {{ $t('login.btnSignIn') }}
              </btn-loading>
            </b-form>
          </validation-observer>

          <!--          <b-card-text class="text-center mt-2">-->
          <!--            <span>{{ $t('login.textNewOnPlatform') }}</span>-->
          <!--            <b-link :to="{name:'register'}">-->
          <!--              <span>&nbsp;-->
          <!--                {{ $t('login.linkCreateAnAccount') }}-->
          <!--              </span>-->
          <!--            </b-link>-->
          <!--          </b-card-text>-->

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  // BIconGoogle,
  BRow, BCol,
  // BLink,
  BFormGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BForm,
  // BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { toastification } from '@core/mixins/toast'
import { $themeConfig } from '@themeConfig'
import { createNamespacedHelpers } from 'vuex'
import BrandLogo from '@/layouts/components/BrandLogo.vue'
import LeftImage from '@/layouts/components/LeftImage.vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('auth')

export default {
  components: {
    // BIconGoogle,
    BRow,
    BCol,
    // BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    // BButton,
    BInputGroupPrepend,

    // components
    LeftImage,
    BrandLogo,
    BtnLoading,

    // validation
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, toastification],
  data() {
    return {
      password: '',
      username: '',
      imgUrl: require('@/assets/images/pages/auth/login.svg'),

      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },

  setup() {
    // App Name
    const { appName } = $themeConfig.app
    return {
      appName,
    }
  },

  computed: {
    ...mapGetters(['status', 'loading', 'message']),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  methods: {
    ...mapActions(['login']),
    async validationForm() {
      const success = await this.$refs.loginValidation.validate()
      if (success) {
        const params = {
          username: this.username,
          password: this.password,
        }
        await this.login({ params })
        if (this.status) {
          this.$router.push({ name: 'dashboard' })
          this.toastSuccess(this.$t('login.textLoginSuccess'))
        } else {
          this.toastFailure(this.message)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
  .max-width{
    max-width: 600px;
  }
</style>
