<template>
  <b-link class="brand-logo">
    <vuexy-logo />
    <h2 class="brand-text text-primary ml-1">
      {{ appName }}
    </h2>
  </b-link>
</template>

<script>

import { $themeConfig } from '@themeConfig'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    VuexyLogo,
    BLink,
  },

  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>
