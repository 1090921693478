<template>
  <b-col
    lg="8"
    class="d-none d-lg-flex align-items-center p-5"
  >
    <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
      <b-img
        fluid
        alt="Forgot password"
        :src="src"
      />
    </div>
  </b-col>
</template>

<script>

import { BImg, BCol } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BCol,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
  },
}
</script>
